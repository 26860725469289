import React from 'react'
import Layout from '../components/Layout.js'
import styled, { css } from 'styled-components'

import { rhythm, media, colors } from '../settings/styles'

import PageTitle from '../components/PageTitle'
import FadeIn from '../components/FadeIn'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import Body from '../components/Body'

const ProjectGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-right: -3rem;
  > * {
    width: ${(1 / 3) * 100}%;
    padding-right: 3rem;
    margin-bottom: 3rem;
  }
  ${media.tablet`${css`
    margin-right: -1rem;
    > * {
      width: ${(1 / 2) * 100}%;
      padding-right: 1rem;
      margin-bottom: 2rem;
    }
  `}`}

  ${media.phone`${css`
    margin-right: 0;
    > * {
      width: 100%;
      padding-right: 0;
      margin-bottom: 2rem;
    }
  `}`}

  @supports (display: blurgh) {
    margin-right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;
    margin-bottom: 5rem;

    > * {
      width: auto;
      padding-right: 0;
      margin-bottom: 0;
    }

    ${media.tablet`${css`
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    `}
  `}

    ${media.phone`${css`
      grid-template-columns: 1fr;
    `}
  `}
  }
`

const Project = styled.article`
  position: relative;

  .title {
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5em;
    text-transform: uppercase;
    font-weight: normal;
    color: ${colors.brandText};

    &::after {
      content: '+';
      margin-left: 1em;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
  }

  .image-wrapper {
    overflow: hidden;
  }

  .image {
    transition: 500ms;
  }

  &:hover {
    .image {
      transform: scale(1.1);
    }
  }
`

const Text = styled(Body)`
  margin: ${rhythm()} auto;
  max-width: 700px;
  text-align: center;
  p {
    font-size: 0.9rem;
  }
`

const ProjectsPage = props => {
  const data = useStaticQuery(graphql`
    query projects {
      page: nodeProjectOverview {
        title
        body {
          processed
        }
      }
      projects: allNodeProject(sort: { fields: field_weight }) {
        edges {
          node {
            id
            title
            path {
              alias
            }
            relationships {
              field_image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 770, maxHeight: 570) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    projects: { edges: projects },
    page: {
      title,
      body: { processed: html },
    },
  } = data

  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Discover our projects | Wow my day</title>
      </Helmet>
      <FadeIn className="l-constrained-wide">
        <Text dangerouslySetInnerHTML={{ __html: html }}></Text>
        <PageTitle full>{title}</PageTitle>
        <ProjectGrid>
          {projects.map(({ node }) => {
            const {
              id,
              title,
              path: { alias },
              relationships: { field_image: image },
            } = node

            return image && image.localFile ? (
              <Project key={id}>
                <div class="image-wrapper">
                  <Img
                    alt={title}
                    className="image"
                    fluid={image.localFile.childImageSharp.fluid}
                  />
                </div>
                <h2 className="title">{title}</h2>
                <Link to={alias}>Lees meer</Link>
              </Project>
            ) : (
              <></>
            )
          })}
        </ProjectGrid>
      </FadeIn>
    </Layout>
  )
}
export default ProjectsPage
