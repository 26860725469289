import styled from 'styled-components'

import { media, rhythm } from '../settings/styles'

const Body = styled.div`
  font-family: Georgia, serif;

  font-size: 1rem;
  color: #d1898f;

  hr {
    border: 0;
    border-top: 1px solid #d1898f;
    max-width: 6em;
    appearance: none;
    margin: ${rhythm(1)} auto;
  }

  ${media.wide`
    font-size: 0.8rem;
  `};

  ${media.tablet`
    font-size: 1rem;
  `};

  h2 {
    font-family: 'Poppins', sans-serif;
    line-height: 1.2;
  }

  > * + * {
    margin-top: 1em;
  }

  li + li {
    margin-top: 1em;
  }

  a {
    color: inherit;
    font-weight: bold;
  }
`

export default Body
